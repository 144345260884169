<template>

  <DefaultCrudView
    @invite-company="openInviteModal"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    containerMedium
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="companies"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="openRevokeModal(item)"
              >
                mdi-home-remove
              </v-icon>
            </template>
            <span>{{ $t('remove') }}</span>
          </v-tooltip>

        </template>

      </v-data-table>

      <DefaultModal
        :title="$t('Revoke external company')"
        ref="revokeCompanyModal"
        @submit="revokeCompany"
        submit-button-text="revoke"
        deleteModal
      >
        <template v-slot:content>
          {{ $t('Are you sure you want to revoke external company') }} <b>{{ selectedCompany ? selectedCompany.name : '' }}</b> ?
        </template>
      </DefaultModal>

      <DefaultModal
        :title="$t('invite_users_from_external_company')"
        ref="inviteCompanyModal"
        @submit="inviteCompany"
        submit-button-text="invite"
      >
        <template v-slot:content>
          <v-text-field
            :label="$t('company_key')"
            v-model="companyToInvite"
            :rules="[v => !!v || $t('form_rules.mandatory')]"
            outlined
            dense
          ></v-text-field>
        </template>
      </DefaultModal>

    </template>

  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }
</style>

<script>

import { mapActions, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'

import AppActions from '@/store/app/actions-types'
import GroupActions from '@/store/core/group/actions-types'
import CompanyActions from '@/store/core/company/actions-types'

export default {
  name: "ExternalCompanies",

  components: {
    DefaultCrudView,
    DefaultModal,
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('groups'),
          prependIcon: this.getIconForCoreSettingsItem('groups'),
          iconColor: 'primary',
          to: {
            name: "Group"
          },
          disabled: false,
          exact: true,
          ripple: true,
        },
        {
          text: !this.selectedGroup ? '' : `${this.selectedGroup.group_title} - ${this.$t('external_companies')}`,
          disabled: true,
        }
      ]
    },

    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ])
  },

  data() {
    return {
      loading: false,
      companies: [],
      options: {},
      itemsLength: 0,
      selectedGroup: null,
      selectedCompany: null,
      companyToInvite: null,
      headers: [
        {text: 'Key', value: 'key'},
        {text: 'Company', value: 'name'},
        {text: 'CNPJ', value: 'cnpj'},
        {text: 'Actions', value: 'actions', sortable: false}
      ],
      actions: [
        {
          text: "invite_company",
          eventToEmit: "invite-company"
        },
      ],
    }
  },

  mounted() {
    this.loading = true

    const groupId = this.$route.params.groupId

    this.[GroupActions.GET_USER_GROUP](groupId)
      .then((userGroup) => {
        this.selectedGroup = userGroup
        
        this.reloadData()
      })
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true
    },
  },

  methods: {
    ...mapActions('core/group', [
      GroupActions.GET_USER_GROUP,
    ]),

    ...mapActions('core/company', [
      CompanyActions.GET_COMPANIES_BY_USER_GROUP,
      CompanyActions.ADD_COMPANY_GROUP_RELATIONSHIP_BY_KEY,
      CompanyActions.DELETE_COMPANY_GROUP_RELATIONSHIP,
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    reloadData() {
      if (!this.selectedGroup) {
        return
      }

      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.page - 1

      options = {
        ...options,
        'filter[only_external]' : "true"
      }

      options.userGroupId = this.selectedGroup.user_group_id

      this.loading = true
      this.[CompanyActions.GET_COMPANIES_BY_USER_GROUP](options)
        .then((result) => {
          this.companies = [...result.items]

          this.itemsLength = result.total

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    openRevokeModal(item) {
      this.selectedCompany = item

      this.$refs.revokeCompanyModal.openModal()
    },

    openInviteModal() {
      this.$refs.inviteCompanyModal.openModal()
    },

    revokeCompany() {
      this.loading = true

      const params = {
        companyId: this.selectedCompany.company_id,
        userGroupId: this.selectedGroup.user_group_id,
      }

      this.[CompanyActions.DELETE_COMPANY_GROUP_RELATIONSHIP](params)
        .then(() => {
          this.reloadData()
          this.$refs.revokeCompanyModal.closeModal()
        })
        .catch(() => {
          this.loading = false
          this.$refs.revokeCompanyModal.closeModal()
        })
    },

    inviteCompany() {
      this.loading = true

      const params = {
        companyKey: this.companyToInvite,
        userGroupId: this.selectedGroup.user_group_id,
      }

      this.[CompanyActions.ADD_COMPANY_GROUP_RELATIONSHIP_BY_KEY](params)
        .then(() => {
          this.reloadData()
          this.$refs.inviteCompanyModal.closeModal()
        })
        .catch(() => {
          this.loading = false
          this.$refs.inviteCompanyModal.closeModal()
        })
    }
  }
}

</script>